import React, { useState, useCallback, SetStateAction, Dispatch } from "react";

import { useIsMounted } from "./useIsMounted";

export function useSaveState<T>(value: T): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState(value);
  const isMounted = useIsMounted();

  const setSaveState: Dispatch<SetStateAction<T>> = useCallback(
    (v: SetStateAction<T>) => {
      if (isMounted()) {
        setState(v);
      }
    },
    [setState],
  );

  return [state, setSaveState];
}
