import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    position: "relative",
    width: "100%",
  },

  mainInfoBlock: {
    display: "grid",
    gridTemplateColumns: "1fr 100px",
    columnGap: 16,
  },

  photoContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",

    "& svg": {
      marginBottom: 5,
    },
  },

  photoWrapper: {
    marginTop: 16,
    paddingTop: "100%",
    position: "relative",

    "& .uploaderRoot": {
      bottom: 0,
      height: "initial",
      left: 0,
      position: "absolute",
      right: 0,
      top: 0,
    },
  },
});
