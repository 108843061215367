import { createUseStyles } from "react-jss";

import { ITheme } from "@theme";

const styles = (theme: ITheme) => ({
  root: {
    height: 140,
    position: "relative",
  },

  loader: {
    alignItems: "center",
    backgroundColor: "rgba(255, 255, 255, .8)",
    bottom: 0,
    display: "flex",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
    zIndex: 10,

    "& .spinner": {
      color: theme.palette.text.secondary,
    },
  },

  buttonRoot: {
    alignItems: "center",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    position: "relative",

    "& .delete": {
      color: theme.palette.error.main,
      cursor: "pointer",
      marginLeft: 20,
    },
  },

  placeholder: {
    alignItems: "center",
    backgroundColor: "#F0F0F0",
    border: "2px dashed #C8C8C8",
    color: "#909090",
    cursor: "pointer",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    marginBottom: 20,
  },

  editButton: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    fontSize: 12,
    height: "20%",
    maxHeight: 25,
    maxWidth: 25,
    justifyContent: "center",
    position: "absolute",
    right: 5,
    top: 5,
    width: "20%",
  },

  editIcon: {
    width: "80%",
    height: "80%",
  },

  label: {
    backgroundColor: "black",
    borderRadius: 5,
    bottom: 5,
    color: "white",
    padding: "3px 5px",
    position: "absolute",
    right: 5,
  },

  image: {
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    height: "100%",
    width: "100%",
  },

  deleteButton: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 5,
    cursor: "pointer",
    display: "flex",
    fontSize: 12,
    height: 25,
    justifyContent: "center",
    left: 5,
    position: "absolute",
    top: 5,
    width: 25,

    "& svg": {
      color: theme.palette.error.main,
      fontSize: 20,
    },
  },
});

export default createUseStyles(styles, { name: "PhotoUploader" });
