import React from "react";

export const trimEventValue = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
): React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> => {
  return {
    ...event,
    target: {
      ...event.target,
      value: event.target.value.trim(),
    },
  };
};
