import React, { useState, useCallback, Fragment } from "react";
import clsx from "clsx";
import { Controller } from "react-hook-form";
import isNil from "lodash/isNil";

import AttachFileOutlined from "@material-ui/icons/AttachFileOutlined";
import LinkOutlined from "@material-ui/icons/LinkOutlined";

import PhotoUploader from "@components/PhotoUploader";
import { LinkOrFileProps, Tab } from "./types";
import { useCss } from "./styles";
import { Input } from "@ui-kit/InputFields";
import { Models } from "@services/api";
import { trimEventValue } from "@helpers/trimEventValue";

const EMPTY_FILE = {
  cloudinaryId: "",
  cloudinaryType: "",
  cloudinaryVersion: 0,
  original: "",
};

export const LinkOrFile: React.FC<LinkOrFileProps> = ({
  label,
  file,
  onChange,
  formData,
  hideControls,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState<Tab>(() => {
    return !!file?.cloudinaryId || !file?.original ? "file" : "link";
  });
  const [innerFile, setInnerFile] = useState<Models.UploadedFile>(() => {
    return !!file?.cloudinaryId ? file : EMPTY_FILE;
  });
  const [innerLink, setInnerLink] = useState<string>(() => {
    return !file || !!file.cloudinaryId ? "" : file.original;
  });
  const css = useCss();

  const handleFileUpload = useCallback(
    (media: Models.Media) => {
      onChange(media.file);
      setInnerFile(media.file);
    },
    [onChange, setInnerFile],
  );

  const handleFileRemove = useCallback(() => {
    onChange(EMPTY_FILE);
    setInnerFile(EMPTY_FILE);
  }, [onChange, setInnerFile]);

  const handleLinkChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      onChange({
        ...EMPTY_FILE,
        original: value.trim(),
      });
      setInnerLink(value);
    },
    [onChange, setInnerLink],
  );

  const handleTabChange = useCallback(
    (tab: Tab) => {
      onChange(
        tab === "link"
          ? {
              ...EMPTY_FILE,
              original: innerLink,
            }
          : innerFile,
      );
      setActiveTab(tab);
    },
    [setActiveTab, onChange],
  );

  return (
    <div {...props} css={css.root}>
      <div css={css.input}>
        {activeTab === "file" ? (
          <div css={css.fileWrapper}>
            <PhotoUploader
              model={file?.cloudinaryId ? ({ file } as Models.Media) : null}
              onUpload={handleFileUpload}
              onDelete={handleFileRemove}
              variant="button"
              emptyButtonText={`Upload ${label.toLocaleLowerCase()}`}
              filledButtonText={`View ${label.toLocaleLowerCase()}`}
              accept="image/png,image/jpeg,application/pdf"
              data-test={`${formData?.name}-uploader`}
              error={formData?.error}
            />
          </div>
        ) : (
          <Fragment>
            {!isNil(formData) ? (
              <Controller
                render={({ onChange, ...rest }) => (
                  <Input
                    {...rest}
                    error={formData.error}
                    label={`${label.capitalize()} link`}
                    onChange={(event) => {
                      handleLinkChange(
                        event as React.ChangeEvent<HTMLInputElement>,
                      );
                      onChange(trimEventValue(event));
                    }}
                    data-test={`${formData.name}-field`}
                  />
                )}
                rules={formData.rules}
                name={formData.name}
                control={formData.control}
                defaultValue={innerLink}
              />
            ) : (
              <Input
                label={`${label.capitalize()} link`}
                onChange={handleLinkChange}
                value={innerLink}
              />
            )}
          </Fragment>
        )}
      </div>

      {!hideControls && (
        <div css={css.togglersContained}>
          <div
            css={css.toggler}
            className={clsx({
              active: activeTab === "file",
            })}
            onClick={() => handleTabChange("file")}
            title="Attach file"
            data-test="attachment-switch"
          >
            <AttachFileOutlined />
          </div>
          <div
            css={css.toggler}
            className={clsx({
              active: activeTab === "link",
            })}
            onClick={() => handleTabChange("link")}
            title="Paste link"
            data-test="link-switch"
          >
            <LinkOutlined />
          </div>
        </div>
      )}
    </div>
  );
};
