import keyBy from "lodash/keyBy";

import { Models } from "@services/api";

type Gender = {
  id: Models.Gender;
  title: string;
  shortTitle: string;
};

export const GENDERS: Gender[] = [
  {
    id: Models.Gender.Male,
    shortTitle: "Boy",
    title: "Male",
  },
  {
    id: Models.Gender.Female,
    shortTitle: "Girl",
    title: "Female",
  },
];

export const gendersMap = keyBy(GENDERS, "id");
