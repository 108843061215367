import cloneDeep from "lodash/cloneDeep";

import { Models } from "@services/api";

const EMPTY_FILE: Models.UploadedFile = {
  original: "",
};

export const getFileEmptyMock = (
  initial: Partial<Models.UploadedFile> = {},
): Models.UploadedFile => {
  return {
    ...cloneDeep(EMPTY_FILE),
    ...initial,
  };
};
