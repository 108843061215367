import cloneDeep from "lodash/cloneDeep";

import { Models } from "@services/api";
import { getFileEmptyMock } from "./file";

const EMPTY_MEDIA: Models.Media = {
  file: getFileEmptyMock(),
};

export const getMediaEmptyMock = (
  initial: Partial<Models.Media> = {},
): Models.Media => {
  return {
    ...cloneDeep(EMPTY_MEDIA),
    ...initial,
  };
};
