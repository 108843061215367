import React, { useRef, useState, Fragment } from "react";
import EditIcon from "@material-ui/icons/Edit";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import DeleteIcon from "@material-ui/icons/Delete";
import clsx from "clsx";
import times from "lodash/times";
import DialogActions from "@material-ui/core/DialogActions";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";
import CircularProgress from "@material-ui/core/CircularProgress";

import HttpClient from "@services/api/HttpService";
import useStyles from "./styles";
import { globalConfig, CLOUDINARY_URLS } from "@constants";
import Button from "@ui-kit/Button";
import Dialog from "@ui-kit/Dialog";
import Typography from "@ui-kit/Typography";
import { Models } from "@services/api";
import { useNotificationStore } from "@store/NotificationStore";
import { getMediaEmptyMock } from "@mocks/media";
import { getFileEmptyMock } from "@mocks/file";
import { useSaveState } from "@hooks/useSaveState";

interface IProps {
  model: Models.Media | null;
  label?: string;
  onUpload: (media: Models.Media) => Promise<void> | void;
  disableEdit?: boolean;
  placeholderContent?: React.ReactNode;
  accept?: string;
  classes?: Partial<{
    root: string;
    button: string;
  }>;
  variant?: "button" | "default";
  emptyButtonText?: string;
  filledButtonText?: string;
  onDelete?: (media: Models.Media) => void;
  onClick?: () => void;
  disableDelete?: boolean;
  "data-test"?: string;
  maxSize?: number;
  minWidth?: number;
  minHeight?: number;
  error?: string;
}

const PhotoUploader: React.FC<IProps> = ({ classes, error, ...props }) => {
  const {
    onUpload,
    model,
    accept = "image/jpeg,image/png",
    label,
    disableEdit,
    placeholderContent,
    variant = "default",
    emptyButtonText,
    maxSize = 9,
    minHeight,
    minWidth,
    filledButtonText,
    onDelete,
    onClick,
    disableDelete = false,
  } = props;
  const [, { setNotification }] = useNotificationStore();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [blob, setBlob] = useState<File | null>(null);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [fetching, setFetching] = useSaveState(false);
  const defaultClasses = useStyles();

  async function uploadFile(file: File) {
    if (file.size > maxSize * 1024 * 1024) {
      setNotification({
        message: `Large file size: (${maxSize}MB max)`,
        type: "error",
      });
      return;
    }

    if (minHeight || minWidth) {
      try {
        await new Promise<void>((resolve, reject) => {
          const image = new Image();

          image.onload = function () {
            const h = (this as any).height;
            const w = (this as any).width;

            if (minHeight && h < minHeight) {
              reject(`Min height: ${minHeight}px`);
              return;
            }
            if (minWidth && w < minWidth) {
              reject(`Min width: ${minWidth}px`);
              return;
            }

            resolve();
          };

          image.src = window.URL.createObjectURL(file);
        });
      } catch (err) {
        setNotification({
          message: err,
          type: "error",
        });
        return;
      }
    }

    setFetching(true);

    try {
      const response = await HttpClient.post<{
        public_id: string;
        resource_type: string;
        secure_url: string;
        version: number;
      }>(
        CLOUDINARY_URLS.UPLOAD,
        {
          file,
          upload_preset: globalConfig.CLOUDINARY_UNSIGNED_KEY,
        },
        "file",
        false,
      );

      let media: Models.Media;

      if (model) {
        media = {
          ...model,
          file: {
            ...model.file,
            cloudinaryId: response.public_id,
            cloudinaryType: response.resource_type,
            original: response.secure_url,
          },
        };
      } else {
        media = getMediaEmptyMock({
          file: getFileEmptyMock({
            cloudinaryId: response.public_id,
            cloudinaryType: response.resource_type,
            cloudinaryVersion: response.version,
            original: response.secure_url,
          }),
        });
      }

      setBlob(file);
      await onUpload(media);
    } finally {
      setFetching(false);
    }
  }

  function handleSave(event: React.ChangeEvent<HTMLInputElement>) {
    const filesList = event.target.files;

    if (!filesList) {
      return;
    }

    times(filesList.length, (i) => {
      void uploadFile(filesList[i]);
    });
  }

  const renderDeleteDialog = () => {
    return (
      <Dialog open onClose={() => setDeleteDialog(false)}>
        <Typography bolded paragraph>
          Remove file?
        </Typography>

        <DialogActions>
          <Button onClick={() => setDeleteDialog(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setDeleteDialog(false);

              if (isFunction(onDelete) && !isNil(model)) {
                onDelete(model);
              }
            }}
            variant="contained"
            color="error"
            data-test="confirm-remove-file"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  if (variant === "button") {
    return (
      <Fragment>
        <div
          className={clsx(defaultClasses.buttonRoot, classes?.root, {
            "form-error": !!error,
          })}
          data-test={props["data-test"]}
        >
          {deleteDialog && renderDeleteDialog()}
          {!model ? (
            <Button
              variant="outlined"
              color="primary"
              size="small"
              onClick={() => inputRef.current?.click()}
              className={classes?.button}
              loading={fetching}
            >
              {emptyButtonText}
            </Button>
          ) : (
            <Fragment>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                href={model.file.original}
                target="_blank"
                className={classes?.button}
                loading={fetching}
              >
                {filledButtonText}
              </Button>

              {!disableDelete && (
                <DeleteIcon
                  className="delete"
                  data-test="remove-file-trigger"
                  onClick={() => setDeleteDialog(true)}
                />
              )}
            </Fragment>
          )}

          <input
            type="file"
            style={{
              display: "none",
            }}
            ref={inputRef}
            onChange={handleSave}
            accept={accept}
          />
        </div>
        {!!error && (
          <Typography color="error" variant="body2">
            {error}
          </Typography>
        )}
      </Fragment>
    );
  }

  return (
    <div
      className={clsx(defaultClasses.root, classes?.root)}
      data-test={props["data-test"]}
    >
      {fetching && (
        <div className={defaultClasses.loader}>
          <CircularProgress className="spinner" />
        </div>
      )}
      {deleteDialog && renderDeleteDialog()}
      <input
        type="file"
        style={{
          display: "none",
        }}
        ref={inputRef}
        onChange={handleSave}
        accept={accept}
      />
      {!model ? (
        <div
          className={defaultClasses.placeholder}
          onClick={() => inputRef.current?.click()}
        >
          {placeholderContent || <AddAPhotoIcon />}
        </div>
      ) : (
        <Fragment>
          {onDelete && (
            <div
              className={defaultClasses.deleteButton}
              onClick={() => setDeleteDialog(true)}
              data-test="remove-file-trigger"
            >
              <DeleteIcon />
            </div>
          )}
          {!disableEdit && (
            <div
              className={defaultClasses.editButton}
              onClick={() => inputRef.current?.click()}
            >
              <EditIcon color="primary" className={defaultClasses.editIcon} />
            </div>
          )}
          <div
            className={defaultClasses.image}
            style={{
              backgroundImage: `url(${
                blob
                  ? URL.createObjectURL(blob)
                  : model.file.w600px
                  ? model.file.w600px
                  : model.file.original
              })`,
            }}
            onClick={onClick}
          />
          {Boolean(label) && (
            <div className={defaultClasses.label} data-test="uploader-label">
              {label}
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
};

PhotoUploader.defaultProps = {
  emptyButtonText: "Choose file",
  filledButtonText: "View file",
};

export default PhotoUploader;
