/* global ProtoExtends */
import React, { forwardRef } from "react";
import clsx from "clsx";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
  KeyboardDatePickerProps,
} from "@material-ui/pickers";
import LuxonUtils from "@date-io/luxon";
import { DateTime } from "luxon";

import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl, { FormControlProps } from "@material-ui/core/FormControl";

type DatePickerVariant = "date" | "datetime";

type DatePickerProps = ProtoExtends<
  KeyboardDatePickerProps,
  {
    error?: string | React.ReactElement;
    value?: string;
    onChange?: (date: string | null) => void;
    formControlClasses?: FormControlProps["classes"];
    variant?: DatePickerVariant;
  }
>;

const getValidMoment = (variant: DatePickerVariant, value?: string) => {
  if (!value) {
    return null;
  }

  const date =
    variant === "datetime"
      ? DateTime.fromISO(value, { setZone: true }).setZone("UTC", {
          keepLocalTime: true,
        })
      : DateTime.fromFormat(value, "yyyy-MM-dd").setZone("UTC", {
          keepLocalTime: true,
        });

  if (!date.isValid) {
    return null;
  }

  const tz = DateTime.local().zoneName;

  return date.setZone(tz, { keepLocalTime: true });
};

const DatePickerComponent = forwardRef(
  (
    {
      onChange,
      error,
      value,
      formControlClasses,
      variant = "datetime",
      ...props
    }: React.PropsWithChildren<DatePickerProps>,
    ref: DatePickerProps["ref"],
  ) => {
    function handleBlur() {
      if (!value) {
        onChange?.(null);
      }
    }

    function handleChange(date: DateTime | null) {
      if (!date?.isValid) {
        onChange?.(null);
        return;
      }

      const utc = date.startOf("day").setZone("UTC", { keepLocalTime: true });

      if (variant === "datetime") {
        onChange?.(utc.toISO());
      }
      if (variant === "date") {
        onChange?.(utc.toFormat("yyyy-MM-dd"));
      }
    }

    return (
      <MuiPickersUtilsProvider utils={LuxonUtils}>
        <FormControl
          fullWidth
          classes={formControlClasses}
          className={clsx({
            "form-error": !!error,
          })}
          error={Boolean(error)}
          margin="normal"
        >
          <KeyboardDatePicker
            {...props}
            format="MM/dd/yyyy"
            placeholder="MM/DD/YYYY"
            value={getValidMoment(variant, value)}
            type="tel"
            error={Boolean(error)}
            onBlur={handleBlur}
            onChange={handleChange}
            animateYearScrolling={false}
            helperText={null}
            InputProps={{
              ref,
            }}
          />
          {Boolean(error) && (
            <FormHelperText className="error">{error}</FormHelperText>
          )}
        </FormControl>
      </MuiPickersUtilsProvider>
    );
  },
);

export default DatePickerComponent;
