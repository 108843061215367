import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const useCss = (): Record<Classes, Interpolation<ITheme>> => ({
  root: {
    display: "flex",

    " .MuiFormControl-root": {
      marginTop: 0,
    },
  },

  input: {
    flex: 1,
  },

  togglersContained: {
    display: "flex",
    flexShrink: 0,
    marginLeft: 20,
  },

  toggler: (theme) => ({
    alignItems: "center",
    borderRadius: 3,
    cursor: "pointer",
    display: "flex",
    height: 30,
    justifyContent: "center",
    width: 30,

    "&.active": {
      backgroundColor: theme.palette.primary.light,
      color: "white",
    },

    "& svg": {
      height: 18,
      width: 18,
    },
  }),

  fileWrapper: {
    marginBottom: 8,
    marginTop: 16,
  },
});
