import React from "react";
import { useFormContext, Controller } from "react-hook-form";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import MediaUploader from "@components/PhotoUploader";
import {
  ControlledInputField,
  ControlledMultilineInputField,
} from "@ui-kit/InputFields";
import { ControlledSelectField } from "@ui-kit/Select";
import DatePicker from "@ui-kit/DatePicker";
import Typography from "@ui-kit/Typography";
import { createCss } from "./styles";
import { GENDERS } from "@constants/genders";
import { required } from "@validators";
import { ChildProfileProps, FormFields } from "./types";
import { LinkOrFile } from "@components/LinkOrFile";
import { Spacer } from "@ui-kit/Spacer";

const ChildProfile: React.FC<ChildProfileProps> = ({
  styles,
  showMedicalInformation,
  showDocuments = true,
  disabledFields,
  showPhoto = false,
}) => {
  const { control, errors } = useFormContext<FormFields>();
  const css = createCss();

  return (
    <form
      data-test="child-profile"
      css={[css.root, styles?.root]}
      id="child-profile-form"
    >
      <div css={css.mainInfoBlock}>
        <div>
          <ControlledSelectField
            items={GENDERS}
            labelProp="shortTitle"
            name="gender"
            label="Gender"
            required
          />

          <Controller
            as={
              <DatePicker
                fullWidth
                disabled={disabledFields?.birthday}
                error={errors.birthday?.message}
                data-test="birthday-field"
                label="Birthday"
                variant="date"
              />
            }
            defaultValue=""
            rules={required()}
            name="birthday"
            control={control}
          />

          <ControlledInputField name="firstName" label="First name" required />

          <ControlledInputField name="lastName" label="Last name" required />
        </div>

        {showPhoto && (
          <div>
            <Controller
              render={({ onChange, value }) => (
                <div css={css.photoWrapper}>
                  <MediaUploader
                    classes={{
                      root: "uploaderRoot",
                    }}
                    model={value?.original ? { file: value } : null}
                    onUpload={(media) => {
                      onChange(media.file);
                    }}
                    placeholderContent={
                      <div css={css.photoContent}>
                        <AddAPhotoIcon />
                        Child photo
                      </div>
                    }
                  />
                </div>
              )}
              name="photo"
              control={control}
            />
          </div>
        )}
      </div>

      {showDocuments && (
        <>
          <Spacer size="sm" />

          <Controller
            render={({ onChange, value }) => (
              <LinkOrFile
                label="Child immunization record"
                file={value}
                onChange={onChange}
                data-test="immunizationRecord-linkOrLink"
                hideControls
              />
            )}
            name="immunizationRecord"
            control={control}
          />

          <Controller
            render={({ onChange, value }) => (
              <LinkOrFile
                label="Child medical history"
                file={value}
                onChange={onChange}
                data-test="medicalHistory-linkOrLink"
                hideControls
              />
            )}
            name="medicalHistory"
            control={control}
          />
        </>
      )}

      {showMedicalInformation && (
        <>
          <Typography gutterBottom paragraphTop>
            Tell us more about your child
          </Typography>
          <ControlledMultilineInputField
            placeholder="Allergies, food intolerance, medical history"
            name="medicalInformation"
          />
        </>
      )}
    </form>
  );
};

ChildProfile.defaultProps = {
  disabledFields: {
    birthday: false,
  },
};

export default ChildProfile;
